body {
  background-color: #121212 !important;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.admin-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.admin-player {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
}
.admin-player-name {
  font-weight: bold;
  font-size: 18px;
}
.thanks {
  color: #37474f;
  margin-top: 50px;
  font-size: 14px;
}
